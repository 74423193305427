<template>
    <div class="nothing-all" v-if="isReady">
        <img src="@/assets/empty/cart.png" alt="" v-if="type == 'cart'">
        <img src="@/assets/empty/404.png" alt="" v-if="type == '404'">
        <img src="@/assets/empty/access.png" alt="" v-if="type == 'access'">
        <img src="@/assets/empty/pay.png" alt="" v-if="type == 'pay'">
        <img src="@/assets/empty/router.png" alt="" v-if="type == 'router'">
        <img src="@/assets/empty/message.png" alt="" v-if="type == 'message'">
        <img src="@/assets/empty/nothing.png" alt="" v-if="type == 'nothing'">
        <h3>Nothing In Here</h3>
        <p></p>
    </div>
</template>

<style lang="less" scoped>
    .nothing-all{width:100%;
        img{width:100%}
        h3{text-align: center;margin-top:-70px;font-size: 30px;}
    }
</style>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'nothing'
        }
    },
    data() {
        return {
            isReady: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.isReady = true
        }, 1000)
    }
}
</script>